<template>
  <div class="hello">
      <div id='container'>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 01.04.2021 19:54:58 UTC
          </div>
          <div id='tip'>
            Opacity Token,
              Short-term signal by D Man

              0.295$ price per token now
              Marketcap 32 million

              Markets: https://www.kucoin.com/trade/OPCT-BTC
              And Uniswap: https://app.uniswap.org/#/swap?outputCurrency=0xdb05ea0877a2622883941b939f0bb11d1ac7c400

              Target 1: 2x
              Target 2: 5x (main)
              Target 3: leave open or 10x

              I have bought this token today - I have decent confidence it will have many good developments in the following 3 weeks. If not, we will dump it then if our target is not reached. Stay tuned!

              You can do DOT portfolio size here.
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OPCT',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
